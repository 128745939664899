import React, { useState, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import { useTheme } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import NiagaraLogo from "../../assets/Niagara.svg";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function Header({ accountInfo, onThemeChange, onLogout, open, setOpen }) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const offlineTimerRef = useRef(null);
  const inactivityTimerRef = useRef(null);

  useEffect(() => {
    if (!open) {
      setAnchorEl(null);
    }
  }, [open]);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      clearTimeout(offlineTimerRef.current);
      offlineTimerRef.current = null;
    };

    const handleOffline = () => {
      setIsOnline(false);
      offlineTimerRef.current = setTimeout(() => {
        onLogout();
      }, 2 * 60 * 60 * 1000); // 2 hours in milliseconds
    };

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimerRef.current);
      inactivityTimerRef.current = setTimeout(() => {
        onLogout();
      }, 2 * 60 * 60 * 1000); // 2 hours in milliseconds
    };

    const handleActivity = () => {
      resetInactivityTimer();
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("touchstart", handleActivity);

    resetInactivityTimer();

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("touchstart", handleActivity);
      clearTimeout(offlineTimerRef.current);
      clearTimeout(inactivityTimerRef.current);
    };
  }, [onLogout]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleThemeChange = () => {
    onThemeChange();
    handleMenuClose();
  };

  const handleRegisterCustomer = () => {
    setOpen(true);
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        border: 1,
        borderColor: theme.palette.text.secondary,
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <img src={NiagaraLogo} alt="Your SVG" style={{ width: "4em" }} />
          <Typography
            sx={{
              marginBottom: "1em",
              color: theme.palette.text.primary,
            }}
          >
            |
          </Typography>
          <Typography
            variant="h7"
            noWrap
            component="a"
            sx={{
              fontSize: "1rem",
              fontFamily: "Poppins, sans-serif",
              marginBottom: "0.6em",
              color: theme.palette.text.primary,
            }}
          >
            Shipment Live Tracking
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <PersonIcon />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                marginBottom: 0.5,
                fontSize: "1rem",
                fontFamily: "Poppins, sans-serif",
                color: "#006098",
              }}
            >
              {accountInfo?.account?.name}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                marginBottom: 0.5,
                fontFamily: "Poppins, sans-serif",
                color: theme.palette.text.primary,
              }}
            >
              User
            </Typography>
          </Box>
          <KeyboardArrowDownIcon
            data-testid="account-button"
            onClick={handleMenuOpen}
          ></KeyboardArrowDownIcon>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={handleThemeChange}>
              {isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
            </MenuItem>
            <MenuItem onClick={handleRegisterCustomer}>
              Register New Customer
            </MenuItem>
            <MenuItem onClick={onLogout}>Logout</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
