import React from "react";
import "./App.css";
import NavigationContainer from "./navigation/Navigation";
import { authProvider } from "./authProvider";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

function App() {
  const [isDarkMode, setIsDarkMode] = React.useState(false);

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light",
      background: {
        default: isDarkMode ? "#101418" : "#FFFFFF",
        paper: isDarkMode ? "#101418" : "#FFFFFF",
      },
      text: {
        primary: isDarkMode ? "#FFFFFF" : "#1D1B20",
        secondary: isDarkMode ? "#A9A9A9" : "#1D1B20",
        button: isDarkMode ? "#FFFFFF" : "#006098",
        buttonhover: isDarkMode ? "#FFFFFF" : "#FFFFFF",
      },
      primary: { main: isDarkMode ? "#101418" : "#E8F2FF" },
      secondary: { main: isDarkMode ? "#2D3135" : "#FFFFFF" },
      button: { main: isDarkMode ? "#2D3135" : "#006098" },
      buttonhover: { main: isDarkMode ? "#004766" : "#004766" },
      border: { main: isDarkMode ? "1px solid #FFFFFF" : "1px solid #006098" },
      FocusStyle: { main: isDarkMode ? "#FFFFFF" : "#006098" },
    },
  });

  const handleThemeChange = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>
        <AzureAD provider={authProvider} forceLogin={true}>
          {({ login, logout, authenticationState, error, accountInfo }) => {
            switch (authenticationState) {
              case AuthenticationState.Authenticated:
                return (
                  <NavigationContainer
                    accountInfo={accountInfo}
                    onLogout={logout}
                    onThemeChange={handleThemeChange}
                  />
                );
              case AuthenticationState.Unauthenticated:
                return (
                  <div>
                    {error && (
                      <p>
                        <span>An error occured during authentication, please try again!</span>
                      </p>
                    )}
                    <p>
                      <span>Hey stranger, you look new!</span>
                      <button onClick={login}>Login</button>
                    </p>
                  </div>
                );
              case AuthenticationState.InProgress:
                return <p>Authenticating...</p>;
              default:
                return (
                  <p>
                    <span>Hey stranger, you look new!</span>
                    <button onClick={login}>Login</button>
                  </p>
                );
            }
          }}
        </AzureAD>
      </div>
    </ThemeProvider>
  );
}

export default App;
